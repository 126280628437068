import React, { useState } from "react";
import { IonPage, useIonViewWillEnter } from "@ionic/react";
import { useLocation } from "react-router-dom";

import useThemeTutorial from "../../../hooks/useThemeTutorial";
import { useWaitApiCall } from "../../../hooks/useWaitApiCall";
import Toast from "../../../molecules/Toast";
import RealizationForm from "../../../templates/RealizationForm";
import {
  categorizedRealizationPath,
  clearSuccessMessage,
  Context,
  createCategory,
  createRealization,
  CREATE_CATEGORY_SUCCESS_MESSAGE,
  CREATE_REALIZATION_BY_CONVERSION_SUCCESS_MESSAGE,
  CREATE_REALIZATION_SUCCESS_MESSAGE,
  fetchCategories,
  fetchClasses,
  fetchGearBoxes,
  fetchTeachers,
  updateReadAtToRealizationFeedback,
  newRealizationPath,
  timelinePath,
  createRealizationFeedbacks,
  fetchRealizationFeedbacks,
  clearErrorMessage,
  CREATE_REALIZATION_FEEDBACKS_SUCCESS_MESSAGE,
  CREATE_REALIZATION_FAILURE_MESSAGE,
} from "../../../store/student";
import {
  ICategory,
  IRealizationCreateParams,
  IRealizationImage,
} from "../../../state";
import styles from "../common.module.scss";

const THEME_KEY = "hasTheme";

const NewRealization = (props: { history: any }) => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const { pathname, search } = useLocation();

  const [isShowToast, setIsShowToast] = useState(false);

  const [themeTutorial, _] = useThemeTutorial(
    contextState.geartheme,
    contextState.student.read_gear_tutorials,
    dispatch,
  );

  const hasTheme = React.useMemo(
    () => Boolean(search.includes(THEME_KEY) && contextState.geartheme),
    [search, contextState.geartheme],
  );

  React.useEffect(() => {
    if (hasTheme && !themeTutorial.completed) {
      props.history.replace(timelinePath);
    }
  }, [themeTutorial, hasTheme, props.history]);

  const createRealizationFunc = React.useCallback(
    (
      realization: IRealizationCreateParams,
      submit_teacher_ids: number[],
      share_class_ids: number[],
      gearbox_id: number | null,
      image?: IRealizationImage,
      geartheme_id?: number,
    ) => {
      if (calling) return;
      dispatch(
        peep(
          createRealization(
            realization,
            submit_teacher_ids,
            share_class_ids,
            gearbox_id,
            image,
            geartheme_id,
          ),
        ),
      );
    },
    [calling, dispatch, peep],
  );

  const createCategoryFunc = React.useCallback(
    (category: ICategory) => {
      if (calling) return;
      dispatch(peep(createCategory(category)));
    },
    [calling, dispatch, peep],
  );

  const updateReadAtToRealizationFeedbackFunc = React.useCallback(
    (realization_feedback_id: number) => {
      dispatch(
        peep(updateReadAtToRealizationFeedback(realization_feedback_id)),
      );
    },
    [dispatch, peep],
  );

  useIonViewWillEnter(() => {
    dispatch(fetchCategories());
    dispatch(fetchTeachers());
    dispatch(fetchClasses());
    dispatch(fetchGearBoxes({ is_check_archived: true }));
  });

  React.useEffect(() => {
    if (calling) return;
    const categoryIDs = contextState.realization?.categories?.map(
      category => category.id,
    );
    if (
      pathname.includes(newRealizationPath) &&
      contextState.success_message === CREATE_CATEGORY_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
      dispatch(peep(fetchCategories()));
    } else if (
      contextState.success_message === CREATE_REALIZATION_SUCCESS_MESSAGE ||
      contextState.success_message ===
        CREATE_REALIZATION_BY_CONVERSION_SUCCESS_MESSAGE
    ) {
      dispatch(clearSuccessMessage());
      props.history.push(
        `${categorizedRealizationPath}/${categoryIDs?.[0] ?? 0}`,
      );
    }
  }, [
    dispatch,
    contextState.success_message,
    contextState.realization,
    props.history,
    calling,
    peep,
    pathname,
  ]);

  React.useEffect(() => {
    if (contextState.error === CREATE_REALIZATION_FAILURE_MESSAGE) {
      setIsShowToast(true);
      dispatch(clearErrorMessage());
    }
  }, [dispatch, contextState.error]);

  const createRealizationFeedbacksFunc = React.useCallback(() => {
    dispatch(peep(createRealizationFeedbacks()));
  }, [dispatch, peep]);

  React.useEffect(() => {
    if (
      contextState.success_message ===
      CREATE_REALIZATION_FEEDBACKS_SUCCESS_MESSAGE
    ) {
      dispatch(peep(fetchRealizationFeedbacks()));
    }
  }, [dispatch, peep, contextState.success_message]);

  return (
    <IonPage className={styles.realizationWrapper}>
      <Toast
        type="danger"
        showToast={isShowToast}
        onClose={() => setIsShowToast(false)}
        message={CREATE_REALIZATION_FAILURE_MESSAGE}
      />
      <RealizationForm
        calling={calling}
        createRealization={createRealizationFunc}
        createCategory={createCategoryFunc}
        pathname={pathname}
        success_message={contextState.success_message}
        categories={contextState.categories}
        teachers={contextState.teachers}
        classes={contextState.classes}
        gearBoxes={contextState.gearBoxes}
        stock_c_to_c_enabled={contextState.stock_c_to_c_enabled}
        realization_feedback_creatable={
          contextState.realization_feedback_creatable
        }
        gearTheme={hasTheme ? contextState.geartheme : null}
        unreadNotiCount={
          contextState.unread_notification_count +
          contextState.unread_comments_count +
          (contextState.student.read_gear_release_note ? 0 : 1)
        }
        student_realization_feedbacks={
          contextState.student_realization_feedbacks
        }
        createRealizationFeedbacks={createRealizationFeedbacksFunc}
        updateReadAtToRealizationFeedback={
          updateReadAtToRealizationFeedbackFunc
        }
      />
    </IonPage>
  );
};

export default NewRealization;
