import { IonContent, IonPage, useIonViewWillEnter } from "@ionic/react";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import clsx from "clsx";
import { parseISO, format } from "date-fns";
import { PieChart, Pie, Cell } from "recharts";

import { NextActionKind, NextActionKinds } from "../../../constants/enum";
import common from "../common.module.scss";
import {
  Context,
  fetchMyRealizations,
  submitExperience,
  assessmentPath,
  fetchAssessment,
  assessmentListPath,
  clearSuccessMessage,
  clearErrorMessage,
  CREATE_ASSESSMENT_SUCCESS_MSG,
  CREATE_ASSESSMENT_FAILURE_MSG,
} from "../../../store/student";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/assessment_arrow.svg";
import { ReactComponent as OriginArrowIcon } from "../../../assets/icons/assessment_originArrow.svg";
import LoginHeader from "../../../organisms/LoginHeader";
import AssessmentComment from "../../../organisms/AssessmentComment";
import Toast from "../../../molecules/Toast";
import AssessmentButton from "../../../molecules/AssessmentButton";
import ComparisonBarChart from "../../../atoms/ComparisonBarChart";
import RadioButton from "../../../atoms/RadioButton";
import { IRealization, IReasonExperience } from "../../../state";
import {
  ASSESSMENT_STATUS,
  SCORE_TEXTS,
  REASON_LABEL,
} from "../../../constants/assessment-content";

import styles from "./AssessmentPage.module.scss";

const AssessmentPage = () => {
  const { dispatch, contextState } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(contextState);
  const history = useHistory();
  const contentRef = React.useRef<HTMLIonContentElement>(null);

  const pramas = useParams<{ expNo: string }>();
  const [currentExpNo, setCurrentExpNo] = React.useState(Number(pramas.expNo));
  const [scoreList, setScoreList] = React.useState({
    experience_score_1: null,
    experience_score_2: null,
    experience_score_3: null,
    experience_score_4: null,
    experience_score_5: null,
    experience_score_6: null,
    experience_score_7: null,
  });
  const [currentReasons, setCurrentReasons] = React.useState<
    IReasonExperience[]
  >([]);
  const [pastReasons, setPastReasons] = React.useState<IReasonExperience[]>([]);
  const [realizations, setRealizations] = React.useState<IRealization[]>([]);
  const [currentReason, setCurrentReason] = React.useState<string>("");
  const [nextActions, setNextActions] = React.useState<
    { kind: NextActionKind; next_action: string }[]
  >([]);
  const [experienceScore, setExperienceScore] = React.useState<number>(0);
  const [toast, setToast] = React.useState<{
    isShow: boolean;
    type: "danger" | "success" | "";
    message: string;
  }>({
    isShow: false,
    type: "",
    message: "",
  });

  React.useEffect(() => {
    setExperienceScore(
      contextState.assessment.assessment_experiences.find(
        assessment => assessment.experience_no === currentExpNo,
      )?.score || 0,
    );
  }, [contextState.assessment, currentExpNo]);

  const lastAssesmentScore = React.useMemo(() => {
    const prevExps =
      contextState.assessment?.last_assessment?.assessment_experiences;
    const prevNo = prevExps?.find(data => data.experience_no === currentExpNo);
    return prevNo ? prevNo.score : null;
  }, [contextState.assessment, currentExpNo]);
  const calcNextExpNo = React.useCallback(() => {
    return currentExpNo >= Object.keys(scoreList).length ? 1 : currentExpNo + 1;
  }, [currentExpNo, scoreList]);
  const [currentAssessmentContent, setCurrentAssessmentContent] =
    React.useState<{
      title: string;
      desc: string;
      detail: string;
      reason: string;
      experienceNo: number;
      colorCode: string;
      hint: string;
    }>();

  React.useEffect(() => {
    const realizationsByExp = contextState.realizations.filter(realization => {
      return Boolean(realization.experience_nos?.includes(currentExpNo));
    });
    setCurrentAssessmentContent(
      ASSESSMENT_STATUS.find(
        assessment => assessment.experienceNo === currentExpNo,
      ),
    );
    setRealizations([...realizationsByExp]);
  }, [currentExpNo, contextState.realizations]);

  React.useEffect(() => {
    setCurrentExpNo(Number(pramas.expNo));
  }, [pramas]);

  const scrollTop = React.useCallback(() => {
    if (!contentRef.current) return;
    contentRef.current.scrollToTop();
  }, []);

  const handleSubmitExperience = React.useCallback(() => {
    if (calling) return;
    dispatch(
      peep(
        submitExperience({
          ...scoreList,
          reasons: [
            {
              experience_no: currentExpNo,
              reason: currentReason,
            },
          ],
          next_actions: nextActions,
          term_no:
            contextState.assessment.term_no === 0
              ? 1
              : contextState.assessment.term_no,
        }),
      ),
    );
  }, [
    calling,
    dispatch,
    peep,
    scoreList,
    currentReason,
    currentExpNo,
    nextActions,
    contextState.assessment,
  ]);

  const handleBackToAssessmentList = React.useCallback(() => {
    handleSubmitExperience();
    history.push(assessmentListPath);
  }, [history, handleSubmitExperience]);

  const handleNextAssessment = React.useCallback(() => {
    const nextExpNo = calcNextExpNo();
    setCurrentExpNo(nextExpNo);
    handleSubmitExperience();
    history.replace({
      pathname: `${assessmentPath}/${nextExpNo}`,
    });
    scrollTop();
  }, [history, calcNextExpNo, handleSubmitExperience, scrollTop]);

  const handleSaveAssessment = React.useCallback(() => {
    handleSubmitExperience();
  }, [handleSubmitExperience]);

  const handleSetNextAction = React.useCallback(
    (kind: NextActionKind, next_action: string) => {
      if (typeof kind !== "number") return;
      setNextActions([
        ...nextActions?.filter(action => action.kind !== kind),
        { kind, next_action },
      ]);
    },
    [nextActions],
  );

  const handleChangeExperienceScore = React.useCallback(
    (e, index) => {
      const targetScore = {
        [`experience_score_${currentExpNo}`]: index + 1,
      };
      setScoreList({ ...scoreList, ...targetScore });
      setExperienceScore(Number(e.target.value));
    },
    [currentExpNo, scoreList],
  );

  React.useEffect(() => {
    const scoreMap = contextState.assessment.assessment_experiences.map(exp => [
      `experience_score_${exp.experience_no}`,
      exp.score,
    ]);
    setScoreList(prevScoreList => {
      return { ...prevScoreList, ...Object.fromEntries(scoreMap) };
    });
    const resonsByExp = contextState.assessment.assessment_experiences
      .map(exp => {
        return currentExpNo === exp.experience_no
          ? exp.current_reasons.map(reason => ({
              experience_no: currentExpNo,
              ...reason,
            }))
          : [];
      })
      .flat();
    const pastResonsByExp = contextState.assessment.assessment_experiences
      .map(exp => {
        return currentExpNo === exp.experience_no
          ? exp.reasons.map(reason => ({
              experience_no: exp.experience_no,
              ...reason,
            }))
          : [];
      })
      .flat();
    setCurrentReason(resonsByExp[0]?.reason || "");
    setCurrentReasons([...resonsByExp]);
    setPastReasons([...pastResonsByExp]);
  }, [contextState.assessment, currentExpNo]);

  useIonViewWillEnter(() => {
    if (contextState.assessment.id === 0) {
      dispatch(peep(fetchAssessment()));
    }
    dispatch(fetchMyRealizations());
  });

  const filteredNextActions = React.useMemo(() => {
    const res: { [key: number]: IReasonExperience[] } = {};
    NextActionKinds.forEach(k => {
      res[k.kind] = contextState.assessment.assessment_next_actions
        ?.filter(item => item.kind === k.kind)
        .map(item => ({
          id: item.id,
          created_at: item.created_at,
          reason: item.next_action,
        }));
    });
    return res;
  }, [contextState.assessment]);

  const expNos = React.useMemo(() => [1, 2, 3, 4, 5, 6, 7], []);

  const assessmentData = React.useMemo(() => {
    const currentExps = contextState.assessment?.assessment_experiences;

    return expNos.map(no => {
      const currentNo = currentExps?.find(data => data.experience_no === no);

      return { data: "", 前回: 0, 今回: currentNo?.score || 0 };
    });
  }, [contextState.assessment, expNos]);

  const assessmentLength = ASSESSMENT_STATUS.length;

  const scoreValueLength = React.useMemo(() => {
    return Object.values(scoreList).filter(value => value !== null);
  }, [scoreList]).length;

  const assessmentRate = [
    { name: "allocated", value: scoreValueLength, colorCode: "#3EBB9D" },
    {
      name: "unallocated",
      value: assessmentLength - scoreValueLength,
      colorCode: "#243140",
    },
  ];

  const reasonLabel = React.useMemo(() => {
    if (lastAssesmentScore === null || experienceScore === 0) {
      return REASON_LABEL.defaultText;
    } else if (experienceScore < lastAssesmentScore) {
      return REASON_LABEL.downText;
    } else {
      return REASON_LABEL.upTexts[experienceScore];
    }
  }, [lastAssesmentScore, experienceScore]);

  const saveButtonText = React.useMemo(() => {
    switch (currentExpNo) {
      case 7:
      case 8:
        return "保存";
      default:
        return "保存して次へ";
    }
  }, [currentExpNo]);

  const saveButtonHandle = React.useMemo(() => {
    switch (currentExpNo) {
      case 7:
        return handleBackToAssessmentList;
      case 8:
        return handleSaveAssessment;
      default:
        return handleNextAssessment;
    }
  }, [
    currentExpNo,
    handleBackToAssessmentList,
    handleSaveAssessment,
    handleNextAssessment,
  ]);

  React.useEffect(() => {
    if (contextState.success_message === CREATE_ASSESSMENT_SUCCESS_MSG) {
      if (currentExpNo !== 8) return;
      setToast({
        isShow: true,
        type: "success",
        message: CREATE_ASSESSMENT_SUCCESS_MSG,
      });
      dispatch(clearSuccessMessage());
    }
  }, [dispatch, currentExpNo, contextState.success_message]);

  React.useEffect(() => {
    if (contextState.error === CREATE_ASSESSMENT_FAILURE_MSG) {
      setToast({
        isShow: true,
        type: "danger",
        message: CREATE_ASSESSMENT_FAILURE_MSG,
      });
      dispatch(clearErrorMessage());
    }
  }, [dispatch, contextState.error]);

  return (
    <IonPage>
      <Toast
        type={toast.type}
        showToast={toast.isShow}
        onClose={() => setToast({ ...toast, isShow: false })}
        message={toast.message}
      />
      <LoginHeader title="自己評価ツール" unreadNotiCount={0} />
      <IonContent className={common.wrapper} ref={contentRef}>
        <div className={clsx(common.container, styles.container)}>
          <div className={styles.layout}>
            <div className={styles.top}>
              <div className={styles.wrapper}>
                <div
                  className={styles.block}
                  style={{
                    borderLeft: `solid 5px ${currentAssessmentContent?.colorCode}`,
                  }}
                >
                  <p className={styles.desc}>
                    {currentExpNo === 8
                      ? "未来について考える"
                      : currentAssessmentContent?.desc}
                  </p>
                  <p className={styles.title}>
                    {currentExpNo === 8
                      ? "まとめ"
                      : currentAssessmentContent?.title}
                  </p>
                </div>
                <div className={styles.block}>
                  {currentExpNo !== 8 && (
                    <div className={styles.pieChart}>
                      <PieChart width={60} height={60}>
                        <Pie
                          data={assessmentRate}
                          dataKey="value"
                          innerRadius={25}
                          outerRadius={30}
                          startAngle={90}
                          endAngle={-270}
                          strokeWidth={0}
                        >
                          {assessmentRate.map(rateData => (
                            <Cell
                              key={rateData.name}
                              fill={rateData.colorCode}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                      <p className={styles.rate}>
                        <span className={styles.count}>{scoreValueLength}</span>
                        <span className={styles.line} />
                        <span className={styles.length}>
                          {assessmentLength}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {currentExpNo === 8 ? (
              <div className={styles.nextAction}>
                <div className={styles.wrapper}>
                  <div className={styles.kindList}>
                    {NextActionKinds.map((k, index) => (
                      <div className={styles.kind} key={k.kind}>
                        <div className={styles.block}>
                          <span className={styles.q}>Q{index + 1}.</span>
                        </div>
                        <div className={styles.block}>
                          <div className={styles.question}>
                            <p className={styles.shortTitle}>{k.shortTitle}</p>
                            <p className={styles.title}>{k.title}</p>
                          </div>
                          <AssessmentComment
                            currentReasons={filteredNextActions[k.kind]}
                            placeholder={k.placeholder}
                            setReason={reason =>
                              handleSetNextAction(k.kind, reason)
                            }
                          />
                          <details className={styles.pastComments}>
                            <summary className={styles.title}>
                              過去のコメントを見る
                              <ArrowIcon className={styles.icon} />
                            </summary>
                            <ul className={styles.pastReasonList}>
                              {contextState.assessment.past_assessment_next_actions
                                ?.filter(
                                  pastAssessment =>
                                    pastAssessment.kind === k.kind,
                                )
                                .map(assessment => (
                                  <li
                                    className={styles.pastReason}
                                    key={assessment.id}
                                  >
                                    <span className={styles.date}>
                                      {format(
                                        parseISO(assessment.created_at),
                                        "yyyy/MM/dd",
                                      )}
                                    </span>
                                    <p className={styles.text}>
                                      {assessment.next_action}
                                    </p>
                                  </li>
                                ))}
                            </ul>
                          </details>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={clsx(styles.buttonWrapper, styles.isPc)}>
                  <AssessmentButton
                    saveText={saveButtonText}
                    handleBackToAssessmentList={handleBackToAssessmentList}
                    handleSaveAssessment={saveButtonHandle}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.inputArea}>
                <div className={styles.wrapper}>
                  <div className={styles.aware}>
                    <div className={styles.question}>
                      <span className={styles.q}>Q1.</span>
                      <p className={styles.title}>
                        {currentAssessmentContent?.detail}
                        と普段から意識していますか？
                      </p>
                    </div>
                    {experienceScore !== 0 && (
                      <div className={styles.scoreText}>
                        {SCORE_TEXTS[experienceScore]}
                      </div>
                    )}
                    <ul className={styles.scoreList}>
                      {[1, 2, 3, 4, 5, 6, 7, 8].map((key, index) => (
                        <li key={key} className={styles.score}>
                          {lastAssesmentScore === key && (
                            <span className={styles.lastAssessmentScore}>
                              前回
                            </span>
                          )}
                          <RadioButton
                            key={key}
                            value={key}
                            selected={experienceScore}
                            handleChange={e => {
                              handleChangeExperienceScore(e, index);
                            }}
                          />
                        </li>
                      ))}
                    </ul>
                    <div className={styles.direction}>
                      <span>意識していない</span>
                      <span>意識している</span>
                    </div>
                  </div>
                  <div className={styles.reason}>
                    <div className={styles.question}>
                      <span className={styles.q}>Q2.</span>
                      <p className={styles.title}>{reasonLabel}</p>
                    </div>
                    <AssessmentComment
                      currentReasons={currentReasons}
                      placeholder={currentAssessmentContent?.hint}
                      setReason={reason => setCurrentReason(reason)}
                    />
                    <details className={styles.pastComments}>
                      <summary className={styles.title}>
                        過去のコメントを見る
                        <ArrowIcon className={styles.icon} />
                      </summary>
                      <ul className={styles.pastReasonList}>
                        {pastReasons.map(reason => (
                          <li className={styles.pastReason} key={reason.id}>
                            <span className={styles.date}>
                              {format(
                                parseISO(reason.created_at),
                                "yyyy/MM/dd",
                              )}
                            </span>
                            <p className={styles.text}>{reason.reason}</p>
                          </li>
                        ))}
                      </ul>
                    </details>
                  </div>
                </div>
                <div className={clsx(styles.buttonWrapper, styles.isPc)}>
                  <AssessmentButton
                    saveText={saveButtonText}
                    handleBackToAssessmentList={handleBackToAssessmentList}
                    handleSaveAssessment={saveButtonHandle}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={styles.layout}>
            {currentExpNo === 8 ? (
              <div className={styles.inputContents}>
                <p className={styles.title}>各素養の入力内容</p>
                <div className={styles.barChart}>
                  <ComparisonBarChart data={assessmentData} summary />
                </div>
                <div className={styles.wrapper}>
                  <div className={styles.assessmentExperienceList}>
                    {contextState.assessment.assessment_experiences.map(
                      experience => (
                        <div
                          className={styles.assessmentExperience}
                          key={experience.experience_no}
                        >
                          <p className={styles.experience}>
                            <span
                              className={styles.border}
                              style={{
                                backgroundColor: ASSESSMENT_STATUS.find(
                                  status =>
                                    experience.experience_no ===
                                    status.experienceNo,
                                )?.colorCode,
                              }}
                            />
                            {
                              ASSESSMENT_STATUS.find(
                                status =>
                                  experience.experience_no ===
                                  status.experienceNo,
                              )?.title
                            }
                          </p>
                          <ul
                            key={experience.experience_no}
                            className={styles.reasonList}
                          >
                            {experience.current_reasons.map(reason => (
                              <li key={reason.id} className={styles.reason}>
                                <p className={styles.text}>{reason.reason}</p>
                                <span className={styles.date}>
                                  {format(
                                    parseISO(reason.created_at),
                                    "yyyy/MM/dd",
                                  )}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ),
                    )}
                  </div>
                </div>
                <div className={clsx(styles.buttonWrapper, styles.isSp)}>
                  <AssessmentButton
                    saveText={saveButtonText}
                    handleBackToAssessmentList={handleBackToAssessmentList}
                    handleSaveAssessment={saveButtonHandle}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.stock}>
                <p className={styles.title}>関連するストック</p>
                <div className={styles.wrapper}>
                  <ul className={styles.realizationList}>
                    {realizations.map(realizationData => (
                      <li
                        key={realizationData.id}
                        className={styles.realization}
                      >
                        <div className={styles.inner}>
                          <div className={styles.header}>
                            {realizationData.kind === "roots" && (
                              <span
                                className={styles.tag}
                                style={{ backgroundColor: "#3E9EFF" }}
                              >
                                ROOTS
                              </span>
                            )}
                            {realizationData.kind === "will" && (
                              <span
                                className={styles.tag}
                                style={{ backgroundColor: "#3C75FC" }}
                              >
                                WILL
                              </span>
                            )}
                            <span className={styles.date}>
                              {format(realizationData.created_at, "yyyy/MM/dd")}
                            </span>
                          </div>
                          <p className={styles.content}>
                            {realizationData.content}
                          </p>
                        </div>
                        {realizationData.origin_realization && (
                          <div className={styles.origin}>
                            <OriginArrowIcon className={styles.icon} />
                            <div className={styles.inner}>
                              <div className={styles.header}>
                                {realizationData.origin_realization.kind ===
                                  "roots" && (
                                  <span
                                    className={styles.tag}
                                    style={{ backgroundColor: "#3E9EFF" }}
                                  >
                                    ROOTS
                                  </span>
                                )}
                                {realizationData.origin_realization.kind ===
                                  "will" && (
                                  <span
                                    className={styles.tag}
                                    style={{ backgroundColor: "#3C75FC" }}
                                  >
                                    WILL
                                  </span>
                                )}
                                <span className={styles.date}>
                                  {format(
                                    new Date(
                                      realizationData.origin_realization.created_at,
                                    ),
                                    "yyyy/MM/dd",
                                  )}
                                </span>
                              </div>
                              <p className={styles.content}>
                                {realizationData.origin_realization.content}
                              </p>
                            </div>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className={clsx(styles.buttonWrapper, styles.isSp)}>
                  <AssessmentButton
                    saveText={saveButtonText}
                    handleBackToAssessmentList={handleBackToAssessmentList}
                    handleSaveAssessment={saveButtonHandle}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AssessmentPage;
